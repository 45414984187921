<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			readOnly
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:readOnly="readOnly"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import partidaActions from './PartidaActions';

export default {
	name: 'partida-grid',
	components: {},
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'partida',
			actions: partidaActions.gridactions,
			modelColumnDefs: {
				estado: {
					createdCell: (td, cellData, rowData) => {
						if (td.parentNode) {
							if (rowData.indposestado === 'V') {
								td.parentNode.classList.add('lsppositivo');
							} else if (rowData.indposestado === 'R') {
								td.parentNode.classList.add('lspnegativo');
							}
						}
					}
				}
			}
		};
	} /*,
	created() {
		console.log('PARTGRID_2');
	},
	mounted() {
		console.log('PARTGRID_3');
		if (document.getElementById('PARTIDAS') && document.getElementById('IFRPART')) {
			console.log(document.getElementById('PARTIDAS').innerHTML);
		}
	}*/
};
</script>

<style lang="postcss">
.lspnegativo {
	color: rgb(169, 68, 66);
	background-color: rgba(236, 193, 193, 0.8) !important;
	//font-weight: bold;
}
.lsppositivo {
	color: rgb(4, 68, 2);
	background-color: rgba(193, 243, 184, 0.8) !important;
	//font-weight: bold;
}
</style>
